import { Pipe, PipeTransform } from '@angular/core';
import { Preset } from '../interfaces/presets';

@Pipe({
  name: 'filterPresets'
})
export class FilterPresetsPipe implements PipeTransform {

  transform(presets: Preset[], owner: string | null): any[] {
    if (!presets) { return []; }
    if (!owner) { return presets; }
    return presets.filter(preset => preset.airline === owner);
  }

}
